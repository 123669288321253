import React from "react"
import Layout from "../../components/Layout"
import Head from "../../components/Head"
import "../../styles/categories.css"
import carnesData from "../../utils/menu/carnesData"
import { Link } from "gatsby"

const pollos = () => (
  <>
    <Head
      description="Tenemos deliciosas carnes como carne asada y sobrebarriga"
      title="Menu: Carnes - Asadero RoqueFer"
      keywords="Carne asada sobrebarriga"
    />
    <Layout>
      <main>
        <div className="products-container">
          <h3>Carnes</h3>
          {carnesData.map(current => {
            return (
              <div className="product">
                <Link to="/pedidos">
                  <picture>
                    <source
                      srcSet={current.imageDesktopSrc}
                      media="(min-width: 500px)"
                    />
                    <img
                      loading="lazy"
                      src={current.imageSrc}
                      alt={current.imageDescription}
                    />
                  </picture>
                </Link>
              </div>
            )
          })}
        </div>
      </main>
    </Layout>
  </>
)

export default pollos
