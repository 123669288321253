import sobrebarriga from "../../../static/assets/images/menu/carnes/unasobrebarriga.jpg"
import sobrebarriga_desktop from "../../../static/assets/images/menu/carnes/desktop/UNA_SOBREBARRIGA.jpg"
import mediaSobrebarriga from "../../../static/assets/images/menu/carnes/mediasobrebarriga.jpg"
import mediaSobrebarriga_desktop from "../../../static/assets/images/menu/carnes/desktop/MEDIA_SOBREBARRIGA.jpg"
import carne from "../../../static/assets/images/menu/carnes/unacarneasada.jpg"
import carne_desktop from "../../../static/assets/images/menu/carnes/desktop/UNA_CARNE_ASADA.jpg"
import mediaCarne from "../../../static/assets/images/menu/carnes/mediacarneasada.jpg"
import mediaCarne_desktop from "../../../static/assets/images/menu/carnes/desktop/MEDIA_CARNE_ASADA.jpg"

const carnesData = [
  {
    imageSrc: sobrebarriga,
    imageDesktopSrc: sobrebarriga_desktop,
    imageDescription: "Sobrebarriga asada con arroz papa y ensalada",
  },
  {
    imageSrc: mediaSobrebarriga,
    imageDesktopSrc: mediaSobrebarriga_desktop,
    imageDescription: "Media sobrebarriga con arroz papa y ensalada",
  },
  {
    imageSrc: carne,
    imageDesktopSrc: carne_desktop,
    imageDescription: "Carne asada con arroz papa y ensalada",
  },
  {
    imageSrc: mediaCarne,
    imageDesktopSrc: mediaCarne_desktop,
    imageDescription: "Media carne con arroz papa y ensalada",
  },
]

export default carnesData
